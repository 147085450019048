<template lang="pug">
    tr(:class="{'bg-gray-100': isStriped}")
        slot
</template>

<script>
export default {
    name: "DefaultTableTr",
    props: {
        index: {
            type: Number,
            validator: value => {
                return Number.isInteger(value)
            }
        },
        striped: {
            type: Boolean,
            default: true,
            validator: value => {
                return (value === true || value === false)
            }
        }

    },
    computed: {
        isStriped() {
            return this.index % 2
        }
    },
}
</script>