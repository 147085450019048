<template lang="pug">
    div
        filters
        employees
</template>

<script>
//Components
import Filters from '@/components/common/filters';
import Employees from '@/components/views/employees';
//Config
import { BUTTONS_VALUES } from '@/configs/names.js';

export default {
    name: "EmployeeListPage",
    components: {
        'filters': Filters,
        'employees': Employees,
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
    },
}
</script>