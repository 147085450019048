<template lang="pug">
    div
        app-table
            slot(slot="header")
                table-header(:list="EMPLOYEE_LIST_TABLE_HEADERS")
            slot(slot="tbody")
                template(v-for="item in employees")
                    app-table-tr(:key="item._id")
                        app-table-td.w-20.p-2
                            template(v-if="item.user && item.user.photo && item.user.photo.path")
                                div.border.rounded-full.w-16.h-16.bg-cover(
                                    :style="`background-image: url(${base_url}/${item.user.photo.path})`"
                                )
                            template(v-else)
                                span Нет фото
                        app-table-td
                            p {{ item.user.name }}
                        app-table-td
                            p {{ item.user.lastname }}         
                        app-table-td
                            p {{ item.user.patronymic }}
                        app-table-td
                            template(v-for="dep in item.department")
                                p(:key="dep._id._id") 
                                    span {{ dep._id.name }} 
                                    span / 
                                    span {{ dep.job_title  }}
                        app-table-td
                            p {{ item.user.email }}
                        app-table-td
                            switcher-style-one.m-auto(
                                :status="+item.user.status ? true : false"
                                :title="{on: SWITCHERS_VALUES.active, off: SWITCHERS_VALUES.blocked}"
                            )
                        app-table-td
                            inline-svg.m-auto(
                                :src="ICONS.bin" 
                                @click="() => { if (deleteFilter) {onDeleteConfirmation(item)} }"
                                :fill-opacity="deleteFilter ? 1 : 0.25"
                            )

        
        button(@click="onLoadMore") Загрузить еще
</template>

<script>
//Components
import DefaultTable from '@/components/common/tables/default-table';
import DefaultTableTr from '@/components/common/tables/default-tr';
import DefaultTableTd from '@/components/common/tables/default-td';
import SwitcherStyleOne from '@/components/common/switchers/style-1';
import TableHeader from '@/components/common/tables/table-header';
//Store
import { EmployeeNameSpace, EmployeeActionTypes, EmployeeGetterTypes, EmployeeMutationTypes } from '@/store/employee/types';
import { RootGetterTypes } from '@/store/types';
//Configs
import { EMPLOYEE_LIST_TABLE_HEADERS, SWITCHERS_VALUES, BUTTONS_VALUES } from '@/configs/names.js';
import { ICONS } from '@/configs/icons';
//Mixins
import { deleteConfirmation, closeModalDialog, _openModal_, _closeModal_ } from '@/mixins/modals';

export default {
    name: "EmployeesComponent",
    mixins: [deleteConfirmation, closeModalDialog, _openModal_, _closeModal_],
    components: {
        'app-table': DefaultTable,
        'app-table-tr': DefaultTableTr,
        'app-table-td': DefaultTableTd,
        'switcher-style-one': SwitcherStyleOne,
        'table-header': TableHeader,
    },
    data() {
        return {
            delete_user_id: null,
            currentPage: 0,
            perPage: 50,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
        SWITCHERS_VALUES() {
            return SWITCHERS_VALUES
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        EMPLOYEE_LIST_TABLE_HEADERS() {
            return EMPLOYEE_LIST_TABLE_HEADERS
        },
        employees() {
            return this.$store.getters[`${EmployeeNameSpace}/${EmployeeGetterTypes.GetEmployees}`]
        },
        base_url() {
            return process.env.VUE_APP_BASE_URL
        },
        deleteFilter() {
            return this.$store.getters[`${RootGetterTypes.GetDeleteFilterStatus}`]
        },
    },
    methods: {
        onLoad({ per_page, page }) {            
            this.$store.dispatch(`${EmployeeNameSpace}/${EmployeeActionTypes.GetEmployees}`, {params: { per_page, page }})
        },
        onLoadMore() {
            this.currentPage = this.currentPage + 1
            this.onLoad({ per_page: this.perPage, page: this.currentPage })
        },
        async onUserChange({ id, value }) {
            await this.$store.dispatch(`${EmployeeNameSpace}/${EmployeeActionTypes.EditEmployee}`, { id, value })
        },
        async onDeleteConfirmation({_id}) {
            this.delete_user_id = _id
            this.deleteConfirmation({ confirm: 'onDeleteUser' })
            
        },
        async onDeleteUser() {
            await this.$store.dispatch(`${EmployeeNameSpace}/${EmployeeActionTypes.DeleteEmployee}`, { id: this.delete_user_id }) 
            this.closeModalDialog()
            this.delete_user_id = null
        }
    },
    mounted() {
        this.onLoad({ per_page: this.perPage, page: this.currentPage })
    },
    beforeDestroy() {
        this.$store.commit(`${EmployeeNameSpace}/${EmployeeMutationTypes.SetEmptyEmployees}`)
    }
}
</script>