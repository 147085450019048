<template lang="pug">
    table.table-auto.border-collapse.border.border-slate-400.w-full.bg-white(:class="{'shadow': shadow}")
        slot(name="header")
        tbody
            slot(name="tbody")
</template>

<script>
export default {
    name: "DefaultTableComponent",
    props: {
        shadow: {
            type: Boolean,
            default: true,
            validator: value => {
                return (value === true || value === false)
            }
        }
    }
}
</script>